"use client";

import dynamic from "next/dynamic";
import FearAndGreedIndex from "@/app/components/FearAndGreedIndex";
import TopNews from "@/app/components/TopNews";
import AdsBanner from "@/app/components/AdsBanner";
import { useTranslations } from "next-intl";
import React from "react";
import PartnerSection from "@/app/components/Partner";
import { BASE_URL } from "@/constant/apiPath";
import Head from "next/head";

// Dynamically import components
const KlardaSearch = dynamic(() => import("@/app/components/KlardaSearch"));
const LearnAboutCrypto = dynamic(() => import("@/app/components/LearnAboutCrypto"));
const Heatmap = dynamic(() => import("@/app/components/Heatmap"));
const Notable = dynamic(() => import("@/app/components/Notable"));
const TrendingComponent = dynamic(() => import("@/app/components/Trending"));
const Signals = dynamic(() => import("@/app/components/Signals"));
const MarketSnapshot = dynamic(() => import("@/app/components/MarketSnapshot"));
const FundRaisingTable = dynamic(() => import("@/app/components/FundRaisingTable"));
const Calendar = dynamic(() => import("@/app/components/Calendar"));
const KlardaPrediction = dynamic(() => import("@/app/components/KlardaPrediction"));

export default function Home() {
  const t = useTranslations("home");

  return (
    <>
      <Head>
        {/* <!-- Title Tag --> */}
        <title>Klarda.com - Top 1 Platform For Optimizing your Digital Assets Portfolio</title>
        <meta
          name="description"
          content="Klarda helps you manage your crypto portfolio across top tokens (Bitcoin, Altcoins, memecoins), exchanges (Binance, Bybit, Coinbase), and chains (BNB Chains, Ethereum, Solana, Ton network), detailed market insights and accurate token price predictions."
        />
        <meta name="image" content={`${BASE_URL}/seo/fb.jpg`} />
        {/* <!--    Open Graph data--> */}
        <meta content={BASE_URL} property="og:url" />
        <meta content="website" property="og:type" />
        <meta
          content="Klarda helps you manage your crypto portfolio across top tokens (Bitcoin, Altcoins, memecoins), exchanges (Binance, Bybit, Coinbase), and chains (BNB Chains, Ethereum, Solana, Ton network), detailed market insights and accurate token price predictions."
          property="og:description"
        />
        <meta content={`${BASE_URL}/seo/fb.jpg`} property="og:image" />

        {/* <!--    Twitter Card data--> */}
        <meta content={BASE_URL} property="twitter:url" />
        <meta content="summary_large_image" property="twitter:card" />
        <meta content="Klarda: Crypto and Portfolio Intelligence" property="twitter:site" />
        <meta
          content="Klarda helps you manage your crypto portfolio across top tokens (Bitcoin, Altcoins, memecoins), exchanges (Binance, Bybit, Coinbase), and chains (BNB Chains, Ethereum, Solana, Ton network), detailed market insights and accurate token price predictions."
          property="twitter:description"
        />
        <meta content="Klarda: Crypto and Portfolio Intelligence" property="twitter:creator" />
        {/* <!--    Twitter Summary card images must be at least 120x120px--> */}
        <meta content={`${BASE_URL}/seo/twitter.jpg`} property="twitter:image" />
      </Head>
      <main className="min-h-screen">
        <div className="flex flex-col gap-6">
          <AdsBanner />
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            <TopNews title={t("News_Analysis")} subTitle={t("Editor_pick")} />
            <div className="lg:col-span-4 space-y-4">
              <FearAndGreedIndex />
              <TrendingComponent />
            </div>
          </div>
          <KlardaSearch />
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            <Heatmap />
            <div className="lg:col-span-4 space-y-4">
              <Notable />
            </div>
          </div>
          <KlardaPrediction />
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            <Signals />
            <div className="lg:col-span-4 space-y-4">
              <MarketSnapshot />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            <FundRaisingTable />
            <div className="lg:col-span-4 space-y-4">
              <Calendar />
            </div>
          </div>

          <LearnAboutCrypto />
          <PartnerSection />
        </div>
      </main>
    </>
  );
}
